.sideBar {
    width: 284px;
}

.sideBarContent{
    height: 100vh;
    background: hsla(0,0%,9%,.6);
}

.active{
    background: #1b1b1b;
    border-right: 3px solid #c73238;
}

.balanceList{
    border-top: 1px solid #2b2b2b;
}

.toolTipHeader div{
    display: flex;
}

.navBarLinkIcon{
    width: 20px;
    height: auto;
}

.mobileSidebarBackdrop{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transition: opacity .15s linear;
    background: #000;
}

.mobileSidebar{
    position: fixed;
    z-index: 40;
    background: #171717;
}